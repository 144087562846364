<template>
  <div class="healthThemeListPdf">
    <div v-for="(item,index) in dataList" :key="index" class="healthThemeListPdf_pages">
      <div v-if="index===0" class="healthThemeListPdf_pages_img">
        <img src="https://image.giantgocloud.com/www/ImageMapping/image/20240116/61E2E31F3C3B423E87F7F2E51C24801A.png"
          alt="" />
      </div>
      <div v-if="index===0" class="healthThemeListPdf_pages_top">
        <div class="healthThemeListPdf_pages_top_title">
          健康主题检测指标清单
        </div>
        <div v-if="index===0" class="healthThemeListPdf_pages_top_title1 a_c">
          <div class="healthThemeListPdf_pages_top_title1_icon a_flex">
            <img style="width: 100%;"
              src="https://image.giantgocloud.com/www/ImageMapping/image/20240115/2C55D5B58C8945F28713E2E396B30A71.png"
              alt="" />
          </div>
          <div class="healthThemeListPdf_pages_top_title1_text">您的健康主题</div>
          <div class="healthThemeListPdf_pages_top_title1_icon a_flex">
            <img style="width: 100%;"
              src="https://image.giantgocloud.com/www/ImageMapping/image/20240115/2C55D5B58C8945F28713E2E396B30A71.png"
              alt="" />
          </div>
        </div>
        <div  v-if="index===0" class="healthThemeListPdf_pages_top_ul">
          <div v-for="(item1,index1) in dataListA" :key="index1" class="healthThemeListPdf_pages_top_li a_c">
            <div class="healthThemeListPdf_pages_top_li_d"></div>
            <div class="healthThemeListPdf_pages_top_li_text">
              {{item1.Name}}
            </div>
          </div>
        </div>
      </div>
      <div  v-if="index===0" class="healthThemeListPdf_pages_top_title1 a_c" >
        <div class="healthThemeListPdf_pages_top_title1_icon a_flex">
          <img style="width: 100%;"
            src="https://image.giantgocloud.com/www/ImageMapping/image/20240115/2C55D5B58C8945F28713E2E396B30A71.png"
            alt="" />
        </div>
        <div class="healthThemeListPdf_pages_top_title1_text">检测指标</div>
        <div class="healthThemeListPdf_pages_top_title1_icon a_flex">
          <img style="width: 100%;"
            src="https://image.giantgocloud.com/www/ImageMapping/image/20240115/2C55D5B58C8945F28713E2E396B30A71.png"
            alt="" />
        </div>
      </div>
      <div v-if="item.listData.length" class="healthThemeListPdf_pages_ul">
        <div class="healthThemeListPdf_pages_ul_title">
          <div class="healthThemeListPdf_pages_ul_title_li1 a_flex">
            检测项目
          </div>
          <div class="healthThemeListPdf_pages_ul_title_li2 a_flex">
            检测指标
          </div>
        </div>
        <div :id="'healthThemeListPdf_pages_ul_content'+index" class="healthThemeListPdf_pages_ul_content">
          <div v-for="(item1,index1) in item.listData" :key="index1" class="healthThemeListPdf_pages_li">
            <div :style="item1.IndexList.length==1?'font-size:20px;':''" class="healthThemeListPdf_pages_li_left a_flex">
             {{item1.Name}}
            </div>
            <div class="healthThemeListPdf_pages_li_right">
              <div v-for="(item2,index2) in item1.IndexList" :key="index2" class="healthThemeListPdf_pages_li_right_li a_c">
                {{item2.IndexName}}<span>({{item2.Remark}})</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import  H5Report  from '@/api/index'
  export default {
    data() {
      return {
        dataListA:[],
        dataList: [],
        pagesNum:0,
        liNum:0
      }
    },
    mounted() {
      this.GetPreciseRiskAnalysisF();

    },
    methods: {
      GetPreciseRiskAnalysisF() {
        const that=this;
        let data={
          CustomerCode:'Mini_eede19db-9801-4e7b-ba45-55c0955e7437',
          IndexName:["免疫年龄分析", "免疫健康", "代谢健康", "心血管健康"],
          TypeId:1
        };
        axios
          .post(
            `${H5Report}/api/SceneCheckPage/GetMedicalIndexDetailList`,
              data
          ).then(res => {
            console.log('列表返回值',res)
            that.dataListA= res.data.data;

            that.setDataA();
            // console.log(this.FoodList, '食疗数据')
          })
      },
      setDataA(){
        const that=this;
        let a={
          listData:[]
        }
        that.dataList.push(a);
        that.setDataB()
      },
      setDataB(){
        const that=this;
        if(that.pagesNum>50){
          return
        }
        if(that.dataListA.length>that.liNum){
          that.dataList[that.pagesNum].listData.push(that.dataListA[that.liNum])
        }else{
          that.setDataC()
          return
        }
        that.$forceUpdate();
        setTimeout(()=>{
          console.log('that.pagesNum','healthThemeListPdf_pages_ul_content'+that.pagesNum)
          let topT=document.getElementById('healthThemeListPdf_pages_ul_content'+that.pagesNum);
          console.log('1111111',topT)
          let topT1=topT.getElementsByClassName('healthThemeListPdf_pages_li')
          console.log('2222222',topT1)
          console.log('topT1[topT1.length-1].offsetTop+topT1[topT1.length-1].offsetHeight',topT1[topT1.length-1].offsetTop+topT1[topT1.length-1].offsetHeight)
          if(topT1[topT1.length-1].offsetTop+topT1[topT1.length-1].offsetHeight>841){
             that.dataList[that.pagesNum].listData.splice(-1,1);
             that.pagesNum++;
             that.setDataA()
          }else{
            that.liNum++
            that.setDataB()
          }

        },1)
      },
      setDataC(){
        const that=this;
        let dom = document.getElementsByClassName('healthThemeListPdf_pages_ul');
        for(let i=0;i<dom.length ;i++){
          let rightDom=dom[i].getElementsByClassName('healthThemeListPdf_pages_li_right');
          console.log('rightDom',rightDom)
          let num=0;
          for(let q=0;q<rightDom.length;q++){
            let liDom = rightDom[q].getElementsByClassName('healthThemeListPdf_pages_li_right_li');
            console.log('liDom',liDom)
            for(let w=0;w<liDom.length;w++){
              num++
              if(num%2!=0){
                liDom[w].style.backgroundColor="#e8f6fb"
              }
            }
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .healthThemeListPdf {
    div{
      box-sizing: border-box;
    }
    .healthThemeListPdf_pages {
      position: relative;
      overflow: hidden;
      width: 595px;
      height: 841px;
      // background-color: rgba(100, 100, 100, 0.2);

      .healthThemeListPdf_pages_img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: -1;

        img {
          width: 100%;
        }
      }

      .healthThemeListPdf_pages_top {
        .healthThemeListPdf_pages_top_title {
          text-align: center;
          margin-top: 75px;
          font-size: 37px;
          font-weight: 600;
          color: #1386B0;
        }

        .healthThemeListPdf_pages_top_ul {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-top: 22px;
          padding: 0 25px 0 24px;

          .healthThemeListPdf_pages_top_li {
            margin-bottom: 22px;
            flex-shrink: 0;
            width: 267px;
            height: 54px;
            background: rgba(25, 161, 210, 0.1);
            border-radius: 10px;
            opacity: 1;

            .healthThemeListPdf_pages_top_li_d {
              margin-left: 30px;
              width: 9px;
              height: 9px;
              border-radius: 50%;
              background-color: #1386B0;
            }

            .healthThemeListPdf_pages_top_li_text {
              margin-left: 20px;
              font-size: 22px;
              font-weight: 500;
              color: #1386B0;
            }
          }
        }
      }
    }

    .healthThemeListPdf_pages_top_title1 {
      display: flex;
      justify-content: space-between;
      margin: 22px auto 0 auto;
      width: 319px;

      .healthThemeListPdf_pages_top_title1_icon {
        width: 34px;
        height: 24px;
      }

      .healthThemeListPdf_pages_top_title1_text {
        font-size: 31px;
        font-weight: 600;
        color: #1386B0;
      }
    }

    .healthThemeListPdf_pages_ul {
      width: 565px;
      margin: 20px auto 0 auto;

      .healthThemeListPdf_pages_ul_title {
        display: flex;
        background-color: #0D7AA1;

        .healthThemeListPdf_pages_ul_title_li1 {
          width: 167px;
          height: 52px;
          font-size: 22px;
          font-weight: 500;
          color: #FFFFFF;
        }

        .healthThemeListPdf_pages_ul_title_li2 {
          width: 397px;
          height: 52px;
          font-size: 22px;
          font-weight: 500;
          color: #FFFFFF;
        }
      }
      .healthThemeListPdf_pages_ul_content{
        width: 100%;
        .healthThemeListPdf_pages_li{
          display: flex;
          border:1px solid #19a2d3;
          border-top: none;
          .healthThemeListPdf_pages_li_left{
            background:#e8f6fb;
            width: 167px;
            padding: 0 30px;
            font-size: 25px;
            font-weight: 500;
            color: #1386B0;
          }
          .healthThemeListPdf_pages_li_right{
            margin-left: 1px;
            font-size: 19px;
            color: #4E4E4E;
            .healthThemeListPdf_pages_li_right_li{
              padding: 0 17px;
              width: 397px;
              height: 52px;
              span{
                margin-left: 10px;
                color: #1386B0;
              }
            }

          }
        }
      }


    }
  }
</style>
